<template>
  <div>
    <Form ref="form" class="editForm" :model="form" :rules="ruleValidate" :label-width="80" :style="{maxHeight:height-200+'px',overflowY:'auto'}">
      <Alert type="warning" show-icon v-if="isCheck">当前计划已关联服务，更改后会替换，请注意！</Alert>
      <FormItem label="任务名称" prop="iname">
        <Input v-model="form.iname" disabled placeholder="任务名称">
          <Button type="primary" @click="checkFlag=true" slot="append">选择服务</Button>
        </Input>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="save">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
    <ys-modal v-model="checkFlag" :width="1000" title="选择服务">
      <!-- 安责险/专项 -->
      <MyCheck :type="type <= 2?type:5" @on-ok="getData" :policyId="modData.length ? modData[0].policyId : ''" v-if="type <= 2||type===9"></MyCheck>
      <!-- 抽查任务 -->
      <inspects :policyId="modData.length ? modData[0].policyId : ''" @on-ok="getData" v-else-if="type===5"></inspects>
      <!-- 演练培训任务 -->
      <event :type="eventType" :policyId="modData.length ? modData[0].policyId : ''" @on-ok="getData" v-else></event>
    </ys-modal>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import MyCheck from "@/components/MyCheck.vue";
import event from "@/components/event.vue";
import inspects from "@/components/inspects.vue";
import {checkInfo} from "@/api/check";
import {eventInfo} from "@/api/train";
import {inspectsInfo} from "@/api/inspects";

export default {
  name: '',
  mixins: [edit],
  components: {event, MyCheck, inspects},
  props: {
    modData: {
      type: Array,
      default: () => []
    },
    eventType: {
      type: Number, default: 1
    },
  },
  data() {
    return {
      ruleValidate: {
        title: [
          {required: true, message: '请填写计划名称', trigger: 'blur',}
        ],
      },
      checkFlag: false,
      type: 1,
      form: {
        id: '',
        iname: '',
        projectName: '',
        checkId: '',
      }
    }
  },
  computed: {
    isCheck() {
      return this.modData.length === 1 && this.modData[0].checkId > 0
    },
  },
  async mounted() {
    this.type = this.modData[0].type
    if (this.modData[0].checkId > 0) {
      let getInfo
      switch (this.type) {
        case 1:
          getInfo = () => checkInfo({id: this.modData[0].checkId})
          break;
        case 2:
          getInfo = () => checkInfo({id: this.modData[0].checkId})
          break;
        case 3:
        case 4:
          getInfo = () => eventInfo({id: this.modData[0].checkId})
          break;
        case 5:
          getInfo = () => inspectsInfo({id: this.modData[0].checkId})
          break;
      }
      await getInfo().then(res => {
        Object.keys(this.form).forEach((item) => {
          this.form[item] = res.data[item]
          // 演练培训任务是多个需要从详情中找到当前计划所关联的那个项目/任务
          if (item === 'projectName' && (this.type === 3 || this.type === 4)) {
            this.form.projectName = (res.data.projects || []).find(ite => {
              return ite.policyPlanId === this.modData[0].id
            }).projectName
          }
        })
      })
    }
    this.form.id = this.modData[0].id
  },
  methods: {
    getData(data) {
      this.form.iname = data[0].iname || data[0].eventName
      this.form.projectName = data[0].projectName
      this.form.checkId = data[0].id
      this.form.eventPolicyId = data[0].id
    },
    save() {
      let bindurl = ''
      switch (this.type) {
        case 1:
        case 2:
          bindurl = '/servePlan/subPlan/SelectCheck'
          break;
        case 3:
        case 4:
          bindurl = '/servePlan/subPlan/SelectEvent'
          break;
        case 5:
          bindurl = '/servePlan/subPlan/SelectInspects'
          break;
      }
      this.ok(bindurl)
    }
  }


}
</script>
<style lang="less" scoped>

</style>
