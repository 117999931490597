<template>
  <div>
    <ys-page-list
        ref="table"
        action="/event/ListPolicy"
        :params="params"
        :multiColumn="false"
        :tableHeight="height-300"
        :headerColumns="headerColumns"
        @on-selection-change="checkItem"
        :isEmptySelect="isEmptySelect">
      <template slot="search">
        <FormItem label="任务状态" prop="type" v-if="type===null">
          <Select v-model="params.type" placeholder="培训任务类型" style="width: 120px;">
            <Option v-for="(item,index) in baseData['培训任务类型']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
      </template>
    </ys-page-list>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok">确定</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'

export default {
  name: '',
  mixins: [pageList],
  props: {
    limit: {
      type: Number,
      default: 0
    },
    policyId: {
      type: Number,
      default: null
    },
    type: {
      type: Number,
      default: null
    }
  },
  computed: {
    isEmptySelect() {
      return this.limit === 1;
    },
  },
  components: {},
  data() {
    return {
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 70, align: 'center'},
        {width: 200, title: '任务名称', key: 'eventName', align: 'left',},
        {title: '保单编号', width: 180, key: 'policyNumber', align: 'center'},
        {minWidth: 250, title: '项目名称', key: 'projectName', align: 'left'},
      ],
      params: {
        type: '',
        policyId: '',
      },
      modal: {}
    }
  },
  created() {
  },
  mounted() {
    this.params.policyId = this.policyId;
    this.params.type = this.type;
    this.getList();
    this.modal = this.$parent.$parent;
  },
  methods: {
    close() {
      this.modal.modShow = false;
    },

    ok() {
      if (this.tableSelectItem.length === 0) {
        this.$Notice.warning({
          title: "操作提示！",
          desc: "请选择至少一条数据！"
        });
        return false;
      }
      if (this.limit !== 0) {
        if (this.tableSelectItem.length > this.limit) {
          this.$Notice.warning({
            title: "操作提示！",
            desc: "最多可选择 " + this.limit + ' 条数据！',
          });
          return false;
        }
      }
      this.$emit('on-ok', this.tableSelectItem);
      this.close();
    }
  }


}
</script>
